"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateDiscountLabel = void 0;
function generateDiscountLabel(onPromotion, price, onPromotionPrice) {
    if (!onPromotion)
        return "";
    var discountLabelPercentage = ((parseFloat(price) - parseFloat(onPromotionPrice)) / parseFloat(price)) * 100;
    return "".concat(discountLabelPercentage.toFixed(0), "% OFF");
}
exports.generateDiscountLabel = generateDiscountLabel;
